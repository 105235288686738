// 秒转分钟(3分24.5秒)
export const formatSeconds = (value) => {
  // let secondTime = value; // 秒
  let secondTime = Math.floor(value * 100) / 100;
  let minuteTime = 0; // 分
  if (secondTime > 60) {
    minuteTime = Number((secondTime / 60 + '').replace(/\.\d+/g, ''));
    secondTime = Number((secondTime % 60).toFixed(2));
  }
  if (secondTime === 60) {
    minuteTime = 1;
    secondTime = 0;
  }
  return fun(minuteTime, secondTime);
  function fun(minute, second) {
    let mStr = '';
    let sStr = '';
    if (minute) {
      mStr = `${minute}分`;
    }
    if (!minute) {
      sStr = `${second}秒`;
    } else {
      sStr = second < 10 ? '0' + second  + '秒' : second + '秒';
    }
    // const m = minute < 10 ? '0' + minute : minute + '';
    return mStr + sStr;
  }
};
/**
 * 随机id ,长度默认是8
 */
 export const randomID = (randomLength = 8) => {
  return Number(
    Math.random()
      .toString()
      .substr(3, randomLength) + Date.now(),
  ).toString(36);
};