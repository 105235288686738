<template>
  <div class="course-container">
    <div class="course-container-title">{{courseInfo.courseName}} </div>
    <div class="course-item-btm">
      <span>课程总时长：{{courseInfo.joinWareDurationSum}}</span>
      <span>已学习时长：{{courseInfo.studyWareDurationSum}}</span>
      <!-- <span>课程中学分：95 分</span>
      <span>已获得学分：95 分</span> -->
    </div>
    <div class="course-group">
      <div class="course-group-tab">
        <a-tabs type="card-gutter" v-if="btnList && btnList.length > 2" @change="wareSearch">
          <a-tab-pane v-for="(item,index) in btnList" :key="index" :title="item.name == null? '':item.name"></a-tab-pane>
        </a-tabs>
      </div>
      <div class="course-group-text">
        <span>课件数: </span>
        <span style="font-size: 20px; color: #FF610D;">{{courseList.length}}</span>
      </div>
    </div>
    <div class="course-list" v-if="courseList && courseList.length > 0">
      <div class="course-item" v-for="(item, index) in courseList" :key="index">
        <div class="course-item-flex" >
          <div class="course-item-left">
            <img :src="'/_fid/' + item.cover" alt="">
          </div>
          <div class="course-item-right">
            <div class="course-item-title line-text-1">{{item.wareName}}</div>
            <div class="course-item-brief line-text-2">{{item.intro}}</div>
            <!-- <div class="course-item-lable">
              <icon-storage style="margin-right: 4px; font-size: 16px;" />
              <span>笔记：12</span>
              <icon-storage style="margin: 0 4px 0 20px; font-size: 16px;" />
              <span>评论：12</span>
            </div> -->
            <div class="course-item-progress">
              <!-- <div class="course-item-progress-c" style="flex: 1;">
                <a-progress :percent="item.num / 100" :stroke-width="6" color="#207AFF" track-color="#f5f5f5">
                  <template v-slot:text="scope" >
                    <div style="width: 80px; text-align: left; color: #207AFF; font-size: 14px;">已学习 {{scope.percent * 100}}%</div>
                  </template>
                </a-progress>
              </div> -->
              <div class="course-item-progress-button">
                <div v-if="item.status == 1"
                  class="btn btn1"
                  style="background-color: #4F17A8;"
                  @click="$router.push({path:'/course/courseware_b',query:{courseId:item.courseId, id:item.wareId, classId:$route.query.classId}})"
                >继续学习</div>
                <!-- <div class="btn btn2" style="margin-left: 10px;">申请结业</div> -->
                <div v-if="item.status == 0" class="course-item-timer">已下架</div>
              </div>
            </div>
          </div>
        </div>
        <div class="course-item-btm">
          <span>课程总时长：{{item.totalDuration}}</span>
          <span>已学习时：{{item.validDuration}}</span>
          <!-- <span>已获得学分：95 分</span> -->
        </div>
      </div>
      <div style="margin: 30px 0; display: flex; justify-content: flex-end;">
        <a-pagination
        :default-current="current"
        :page-item-style="{ 'border': '1px solid rgba(0,0,0,0.1500)', 'border-radius': '2px' }"
        :active-page-item-style="{ 'background-color': 'transparent', 'border': '1px solid #4F17A8','color': '#4F17A8'}"
        :total="total"
        :default-page-size="size"
        @change="change" />
      </div>
    </div>
    <div v-else ><a-empty /></div>
  </div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { rateWarePage,groupList }  from '@/api/course'
import { formatSeconds } from '@/utils/util';
export default {
  setup () {
    const state = reactive({
      total: 110,
      courseList: [],
      courseInfo:JSON.parse(sessionStorage.getItem('courseInfo')),
      current:1,
      size:10,
      btnList:[],//分组
    });
    const route = useRoute();
    const getList = (current, size) => {
      rateWarePage({current, size, courseId: route.query.courseId,classId:route.query.classId}).then(res => {
         state.courseList = res.result.records;
         state.courseList.forEach((item, index) => {
           item.totalDuration = formatSeconds(item.totalDuration)
           item.validDuration = formatSeconds(item.validDuration)
         })
         state.current = res.result.current;
         state.total = res.result.total;
      })
    };
    getList();
    const change = (val) => {
      console.log('第几页' + val)
      getList(val, 10)
    }
    //分组
    const getGroupList = () => {
      groupList({id:route.query.classId}).then(res => {
        state.btnList = res.result;
        state.btnList.unshift({name:'全部',id:''})
      })
    };
    getGroupList();
    //分组查询
    const wareSearch = (val) => {
      console.log(state.btnList[val].id);
      const wareGroupId = state.btnList[val].id;
      rateWarePage({current:1, size:10, courseId: route.query.courseId,classId:route.query.classId, wareGroupId}).then(res => {
         state.courseList = res.result.records;
         state.courseList.forEach((item, index) => {
           item.totalDuration = formatSeconds(item.totalDuration)
           item.validDuration = formatSeconds(item.validDuration)
         })
         state.current = res.result.current;
         state.total = res.result.total;
      })
    };
    
    return {
      ...toRefs(state),
      change,
      getGroupList,
      wareSearch,
    }
  }
}
</script>

<style lang="less" >
.course-container {
  .arco-tabs-type-card-gutter > .arco-tabs-content {
    display: none !important;
  }
  .arco-tabs-nav-type-card-gutter .arco-tabs-tab:first-child {
    margin-left: 0px;
  }
  .arco-tabs-nav-type-card-gutter .arco-tabs-tab {
    margin-left: 20px;
    border-radius: 4px;
    // padding: 4px 20px;
  }
  .arco-tabs-nav-type-card-gutter .arco-tabs-tab.arco-tabs-tab-active {
    background: #4F17A8;
    color: #FFFFFF;
  }
  .arco-tabs-nav::before {
    display: none;
  }
}
</style>

<style lang="less" scoped>
.course-item-timer {
  height: 70px;
  line-height: 70px;
  width: 70px;
  border-radius: 50%;
  border: 1px solid #999;
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: rgba(0,0,0,0.3);
  text-align: center;
  color: #515050;
}
.course-container {
  width: 100%;
  padding: 20px 30px;
  box-sizing: border-box;
  background-color: #fff;
  .course-container-title {
    color: #323233;
    font-size: 20px;
    font-weight: 700;
  }
  .course-item-btm {
    width: 100%;
    height: 32px;
    background: #F9F9F9;
    border-radius: 4px;
    margin: 20px 0;
    display: flex;
    align-items: center;
    padding: 0 12px;
    box-sizing: border-box;
    span {
      width: 180px;
      color: #323233;
      font-size: 14px;
    }
  }
  .course-group {
    display: flex;
    padding-bottom: 20px;
    border-bottom: 1px solid #E9E9E9;
    .course-group-tab {
      display: flex;
      flex: 1;
      overflow: hidden;
    }
    .course-group-text {
      font-size: 16px;
      margin-left: 100px;
      font-size: 16px;
      color: rgba(0,0,0,0.65);
    }
  }
}
.course-item {
  border-bottom: 1px solid #E9E9E9;
  .course-item-flex {
    display: flex;
    padding-top: 22px;
    .course-item-left {
      width: 224px;
      height: 126px;
      background: #FFFFFF;
      box-shadow: 0px 1px 4px 0px rgba(0,0,0,0.1);
      border-radius: 4px;
      margin-right: 12px;
      img {
        display: block;
        width: 224px;
        height: 126px;
        border-radius: 4px;
      }
    }
    .course-item-right {
      flex: 1;
      position: relative;
      .course-item-title {
        font-size: 16px;
        color: #333333;
        font-weight: 700;
        margin-bottom: 8px;
      }
      .course-item-brief {
        font-size: 14px;
        color: #7B7B7B;
        height: 48px;
        line-height: 24px;
        margin-bottom: 10px;
      }
      .course-item-lable {
        display: flex;
        font-size: 14px;
        color: #323233;
      }
      .course-item-progress {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .course-item-progress-button {
          display: flex;
          margin-left: 50px;
        }
      }
    }
  }
}
</style>
